export const reporting2016 = [
  {
    name: "Информация об открытом акционерном обществе и его деятельности",
    src: "../../pdf/Информация об открытом акционерном обществе и его деятельности.pdf",
  },
  {
    name: "Бухгалтерский баланс",
    src: "../../pdf/Бухгалтерский баланс.pdf",
  },
  {
    name: "Отчет о движении денежных средств",
    src: "../../pdf/Отчет о движении денежных средств.pdf",
  },
  {
    name: "Отчет о прибылях и убытках",
    src: "../../pdf/Отчет о прибылях и убытках.pdf",
  },
  {
    name: "Отчет об изменении собственного капитала",
    src: "../../pdf/Отчет об изменении собственного капитала.pdf",
  },
];

export const reporting2017 = [
  {
    name: "Информация об открытом акционерном обществе и его деятельности 2017",
    src: "../../pdf/Информация об открытом акционерном обществе и его деятельности 2017.pdf",
  },
  {
    name: "Бухгалтерский баланс 2017",
    src: "../../pdf/Бухгалтерский баланс 2017.pdf",
  },
  {
    name: "Отчет о движении денежных средств 2017",
    src: "../../pdf/Отчет о движении денежных средств 2017.pdf",
  },
  {
    name: "Отчет о прибылях и убытках 2017",
    src: "../../pdf/Отчет о прибылях и убытках 2017.pdf",
  },
  {
    name: "Отчет об изменении собственного капитала 2017",
    src: "../../pdf/Отчет об изменении собственного капитала 2017.pdf",
  },
];

export const reporting2018 = [
  {
    name: "Информация об открытом акционерном обществе и его деятельности 2018",
    src: "../../pdf/Информация об открытом акционерном обществе и его деятельности 2018.pdf",
  },
  {
    name: "Бухгалтерский баланс 2018",
    src: "../../pdf/Бухгалтерский баланс 2018.pdf",
  },
  {
    name: "Отчет о движении денежных средств 2018",
    src: "../../pdf/Отчет о движении денежных средств 2018.pdf",
  },
  {
    name: "Отчет о прибылях и убытках 2018",
    src: "../../pdf/Отчет о прибылях и убытках 2018.pdf",
  },
  {
    name: "Отчет об изменении собственного капитала 2018",
    src: "../../pdf/Отчет об изменении собственного капитала 2018.pdf",
  },
  {
    name: "Справка об изменении валюты баланса по состоянию на 31 декабря 2018 г.",
    src: "../../pdf/Справка об изменении валюты баланса по состоянию на 31 декабря 2018 г..pdf",
  },
];

export const reporting2019 = [
  {
    name: "Информация об открытом акционерном обществе и его деятельности 2019",
    src: "../../pdf/Информация об открытом акционерном обществе и его деятельности 2019.pdf",
  },
  {
    name: "Бухгалтерский баланс 2019",
    src: "../../pdf/Бухгалтерский баланс 2019.pdf",
  },
  {
    name: "Отчет о движении денежных средств 2019",
    src: "../../pdf/Отчет о движении денежных средств 2019.pdf",
  },
  {
    name: "Отчет о прибылях и убытках 2019",
    src: "../../pdf/Отчет о прибылях и убытках 2019.pdf",
  },
  {
    name: "Отчет об изменении собственного капитала 2019",
    src: "../../pdf/Отчет об изменении собственного капитала 2019.pdf",
  },
  {
    name: "Справка об изменении валюты баланса по состоянию на 31 декабря 2019 г.",
    src: "../../pdf/Справка об изменении валюты баланса по состоянию на 31 декабря 2019 г..pdf",
  },
  {
    name: "Аудиторское заключение за 2019 год",
    src: "../../pdf/Аудиторское заключение за 2019 год.pdf",
  },
];

export const reporting2020 = [
  {
    name: "Информация об открытом акционерном обществе и его деятельности 2020",
    src: "../../pdf/Информация об открытом акционерном обществе и его деятельности 2020.pdf",
  },
  {
    name: "Бухгалтерский баланс 2020",
    src: "../../pdf/Бухгалтерский баланс 2020.pdf",
  },
  {
    name: "Отчет о движении денежных средств 2020",
    src: "../../pdf/Отчет о движении денежных средств 2020.pdf",
  },
  {
    name: "Отчет о прибылях и убытках 2020",
    src: "../../pdf/Отчет о прибылях и убытках 2020.pdf",
  },
  {
    name: "Отчет об изменении собственного капитала 2020",
    src: "../../pdf/Отчет об изменении собственного капитала 2020.pdf",
  },
  {
    name: "Справка об изменении валюты баланса по состоянию на 31 декабря 2020 г.",
    src: "../../pdf/Справка об изменении валюты баланса по состоянию на 31 декабря 2020 г..pdf",
  },
  {
    name: "Аудиторское заключение за 2020 год",
    src: "../../pdf/Аудиторское заключение за 2020 год.pdf",
  },
];

export const reporting2021 = [
  {
    name: "Годовой отчёт о деятельности предприятия 2021",
    src: "../../pdf/Годовой отчёт о деятельности предприятия 2021.pdf",
  },
  {
    name: "Бухгалтерский баланс 2021",
    src: "../../pdf/Бухгалтерский баланс 2021.pdf",
  },
  {
    name: "Отчет о движении денежных средств 2021",
    src: "../../pdf/Отчет о движении денежных средств 2021.pdf",
  },
  {
    name: "Отчет о прибылях и убытках 2021",
    src: "../../pdf/Отчет о прибылях и убытках 2021.pdf",
  },
  {
    name: "Отчет об изменении собственного капитала 2021",
    src: "../../pdf/Отчет об изменении собственного капитала 2021.pdf",
  },
  {
    name: "Справка об изменении валюты баланса по состоянию на 31 декабря 2021 г.",
    src: "../../pdf/Справка об изменении валюты баланса по состоянию на 31 декабря 2021 г..pdf",
  },
  {
    name: "Аудиторское заключение за 2021 год",
    src: "../../pdf/Аудиторское заключение за 2021 год.pdf",
  },
];

export const reporting2022 = [
  {
    name: "Годовой отчёт о деятельности предприятия 2022",
    src: "../../pdf/Годовой отчёт о деятельности предприятия 2022.pdf",
  },
  {
    name: "Бухгалтерский баланс 2022",
    src: "../../pdf/Бухгалтерский баланс 2022.pdf",
  },
  {
    name: "Отчет о движении денежных средств 2022",
    src: "../../pdf/Отчет о движении денежных средств 2022.pdf",
  },
  {
    name: "Отчет о прибылях и убытках 2022",
    src: "../../pdf/Отчет о прибылях и убытках 2022.pdf",
  },
  {
    name: "Отчет об изменении собственного капитала 2022",
    src: "../../pdf/Отчет об изменении собственного капитала 2022.pdf",
  },
  {
    name: "Справка об изменении валюты баланса по состоянию на 31 декабря 2022 г.",
    src: "../../pdf/Справка об изменении валюты баланса по состоянию на 31 декабря 2022 г..pdf",
  },
  {
    name: "Аудиторское заключение за 2022 год",
    src: "../../pdf/Аудиторское заключение за 2022 год.pdf",
  },
];

export const pricesLinks = [
  {
    name: "Прайс-лист",
    src: "../../pdf/pdf-prices/price-list.pdf",
  },
  {
    name: "Прайс-лист для физических лиц",
    src: "../../pdf/pdf-prices/price-list-fizical.pdf",
  },
  {
    name: "Прайс-лист на строительные материалы",
    src: "../../pdf/pdf-prices/price-list-building.pdf",
  },
];

export const anticorruptionLinks = [
  {
    name: "План мероприятий по противодействию коррупции на 2023-2025 годы",
    src: "/pdf/pdf-anticorruption/План мероприятий по противодействию коррупции на 2023-2025 годы.pdf",
    id: "1",
  },
  {
    name: "Положение о комиссии по противодействию коррупции",
    src: "/pdf/pdf-anticorruption/Положение о комиссии по противодействию коррупции.pdf",
    id: "2",
  },
  {
    name: "Контактные телефоны членов комиссии",
    src: "/pdf/pdf-anticorruption/Контактные телефоны членов комиссии.pdf",
    id: "3",
  },
  {
    name: "Положение о порядке работы телефона доверия",
    src: "/pdf/pdf-anticorruption/Положение о порядке работы телефона доверия.pdf",
    id: "4",
  },
  {
    name: "Положение о сдаче коррупционных подарков",
    src: "/pdf/pdf-anticorruption/Положение о сдаче коррупционных подарков.pdf",
    id: "5",
  },
  {
    name: "План работы комиссии на 2024 год",
    src: "/pdf/pdf-anticorruption/План работы комиссии на 2024 год.pdf",
    id: "6",
  },
  {
    name: "Профилактические статьи",
    src: "/pdf/pdf-anticorruption/Профилактические статьи.pdf",
    id: "7",
  },
  {
    name: "Памятка о борьбе с коррупцией",
    src: "/pdf/pdf-anticorruption/Памятка о борьбе с коррупцией.pdf",
    id: "8",
  },
  {
    name: "Антикоррупционное законодательство",
    src: "/pdf/pdf-anticorruption/Антикоррупционное законодательство.pdf",
    id: "9",
  },
];
